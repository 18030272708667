@mixin post-list {
  list-style: none;

  > li + li {
    margin-top: 1.6rem;
  }

  p {
    text-indent: 0;
  }

  img {
    max-width: 100%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 20px;
}

body {
  font-family: 'Noto Sans', sans-serif;
  line-height: 1.6;
  font-weight: 400;
  padding-bottom: 3.2rem;
}

a {
  color: #072b2e;
  transition: color .4s;

  &:hover {
    color: mix(#072b2e, #fff, 75%);
  }
}

[data-geosymmetric] {
  width: 100%;
  overflow: hidden;
  height: 0;

  @media (min-width: 769px) {
    height: 100vh;
  }

  ~ .page-content {
    max-width: 32rem;

    @media (min-width: 769px) {
      margin-top: -50vh;
      min-height: 50vh;
    }
  }

  @at-root .story & {
    @media (min-width: 769px) {
      height: 50vh;
    }

    ~ .page-content {
      max-width: 48rem;

      @media (min-width: 769px) {
        margin-top: -25vh;
        min-height: 25vh;
      }
    }
  }
}

.page-header {
  font-weight: 700;

  @media (min-width: 769px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    opacity: .25;
    transition: opacity .4s;

    &:hover {
      opacity: 1;

      nav {
        transform: scaleY(1);

        a::after {
          opacity: 0;
        }
      }
    }
  }

  a {
    display: block;
    position: relative;
    padding: 1rem;
    color: #000;
    text-decoration: none;
  }

  nav {
    text-align: right;
    @media (min-width: 769px) {
      margin-left: auto;
      display: flex;
      transform: scaleY(.25);
      transition: transform .4s;

      a::after {
        display: block;
        position: absolute;
        content: '';
        top: 1rem;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        background: #000;
        opacity: 1;
        transition: opacity .4s;
      }
    }
  }
}

h1, h2, h3 {
  font-weight: 700;
}

h1 {
  font-size: 1.6rem;
}

h2 {
  font-size: 1rem;
}

h3 {
  font-size: .625rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.page-content,
.page-footer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1rem 0;

  @media (min-width: 769px) {
    padding: 1.6rem 1.6rem 0;
  }
}

.page-content {
  background: #fff;
  position: relative;

  > * + *,
  article > * + * {
    margin-top: 1.6rem;
  }

  small {
    font-size: .8rem;
  }

  p {
    text-indent: 1.6rem;
  }

  hr {
    border: 0;
    height: 1px;
    background: transparentize(#072b2e, .75);
  }

  article,
  aside {
    ul,
    ol {
      padding-left: 1.6rem;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  @media (min-width: 769px) {
    article p:not(:first-of-type) img,
    article blockquote,
    article video,
    article .cp_embed_wrapper,
    article .highlight,
    aside {
      max-width: 40rem - 2 * 1.6rem;
      margin-left: -4rem;
      margin-right: -4rem;
    }
  }

  .highlight {
    overflow-x: auto;

    .gutter {
      padding-right: 1.6rem;
    }
  }

  blockquote {
    padding-left: 1.6rem;
    border-left: .625rem solid transparentize(#072b2e, .75);

    p {
      text-indent: 0;
    }
  }
}

.category-posts {
  @media (min-width: 769px) {
    padding: 1.6rem;
    border: 1px solid transparentize(#072b2e, .75);
    border-radius: .625rem;
  }
}

.post-list {
  @include post-list;
}

.page-footer {
  max-width: 40rem;
  margin-top: 1.6rem;

  @media (max-width: 768px) {
    > * + * {
      margin-top: 1.6rem;
    }
  }

  @media (min-width: 769px) {
    display: flex;
    justify-content: center;

    > * {
      flex: 0 0 calc(50% - .8rem);

      &:only-child {
        flex: 0 0 50%;
      }

      & + * {
        margin-left: 1.6rem;
      }
    }
  }

  ul {
    @include post-list;
  }
}

.all-posts {
  display: block;
  padding: .625rem 1.6rem;
  text-align: center;
  border: 1px solid transparentize(#072b2e, .75);
  border-radius: .625rem;
  text-decoration: none;
}

.about {
  @media (min-width: 769px) {
    padding: 1.6rem;
    border: 1px solid transparentize(#072b2e, .75);
    border-radius: .625rem;
    align-self: flex-start;
  }

  > svg {
    position: absolute;
  }

  &__photo {
    display: block;
    margin: 0 auto 1.6rem;
  }

  p + h3 {
    margin-top: 1.6rem;
  }

  &__icons {
    a {
      display: flex;
      align-items: center;

      [data-icon] {
        font-size: 1.2rem;
        margin-right: .6rem;
      }
    }
  }
}

@import 'index';
@import 'cv';
@import 'pygments/default.css';
@import 'print';
