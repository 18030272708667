@media screen {
  .only-print {
    display: none !important;
  }
}

@media print {
  .only-screen {
    display: none !important;
  }

  @page {
    margin: 3.2rem;
  }

  :root {
    font-size: 15px;
  }

  .body {
    padding-bottom: 0;
  }

  [data-geosymmetric],
  .page-header {
    display: none;
  }

  .page-content {
    margin-top: 0 !important;
    max-width: none !important;
    padding: 0;
  }

  .cv__experience {
    page-break-after: always;
  }
}
