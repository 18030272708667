.cv {
  @media (max-width: 768px) {
    > * + * {
      margin-top: 1.6rem;
    }
  }

  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, auto);
    grid-gap: 1.6rem;

    &__contact {
      grid-column: 1;
      grid-row: 1;
    }

    &__summary {
      grid-column: 2;
      grid-row: 1;
    }

    &__skills {
      grid-column: 1 / span 2;
      grid-row: 2;
    }

    &__experience {
      grid-column: 1 / span 2;
      grid-row: 3;
    }

    &__volunteer {
      grid-column: 1 / span 2;
      grid-row: 4;
    }

    &__characteristics {
      grid-column: 1;
      grid-row: 5;
    }

    &__interests {
      grid-column: 1;
      grid-row: 6;
    }

    &__links {
      grid-column: 2;
      grid-row: 5 / span 2;
    }
  }

  h2 {
    text-align: center;
    border-bottom: 4px solid transparentize(#072b2e, .75);
    margin-bottom: .8rem;
  }

  p {
    text-indent: 0;
  }

  ul {
    list-style-type: none;
  }

  &__summary {
    @media (min-width: 769px) {
      padding-bottom: .8rem;
      border-bottom: 4px solid transparentize(#072b2e, .75);
    }
  }

  &__skills {
    @media (max-width: 768px) {
      li + li {
        margin-top: .8rem;
      }
    }

    @media (min-width: 769px) {
      ul {
        display: flex;
        margin: 0 -.8rem;
      }

      li {
        width: 25%;
        padding: 0 .8rem;
      }
    }
  }

  &__experience {
    ul {
      list-style-type: disc;
      padding-left: 1.6rem;

      + h3 {
        margin-top: .8rem;
      }
    }
  }

  &__interests {
    li:not(:last-child) span {
      margin-bottom: .8rem;
    }
  }

  &__links {
    li + li {
      margin-top: .8rem;
    }
  }

  &-disclaimer:empty {
    margin-top: 0;
  }

  &__skills span,
  &__experience p,
  &__volunteer p,
  &__interests span,
  &__links span,
  &-disclaimer {
    font-size: .8rem;
    display: block;
  }
}
