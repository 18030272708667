.index {
  @media (max-width: 768px) {
    > * + * {
      margin-top: 1.6rem;
    }
  }

  @media (min-width: 769px) {
    display: flex;
    justify-content: space-between;

    > * {
      flex: 0 0 calc(50% - .8rem);
    }
  }

  ul {
    @include post-list;
  }

  p {
    text-indent: 0;
  }
}
